import type PagedData from '~/utils/pagedData';

export type ActionError = "unauthorized" | "dataInvalid" | "generic"

export const createDefaultErrorHandler = (errorCallback: (error: ActionError) => any) => (ctx: { response?: { status: number } }) => {
    console.error("[ResponseErrorHandler]", "Received status", ctx.response?.status ?? "UNKNOWN")
    switch (ctx.response?.status) {
        case 401: errorCallback("unauthorized"); break
        case 400: errorCallback("dataInvalid"); break;
        default: errorCallback("generic"); break
    }
}

export const dateSorter = <TItem extends { date: string }>(a: TItem, b: TItem) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime()
}

export const useItemWithQueryCollection = <TItem extends WithId, TGroupParams extends object, TSingleParams extends object, TQueryItem = TItem>(
    baseUrl: ItemStoreUrl, 
    sorter: (a: TItem, b: TItem) => number,
    asyncQueryMapper: (i: TQueryItem) => Promise<TItem | undefined>,
    errorCallback: (error: ActionError) => any
) => {
    const items = ref<Map<string, TItem>>(new Map()) as Ref<Map<string, TItem>>
    const nextPageIndex = ref(0)
    const isComplete = ref(false)

    const fetchMoreAsync = async (params?: TGroupParams) => {
        try {
            if (isComplete.value) {
                return
            }
            const data = (await $fetch(baseUrl, {
                query: !!params ? {
                    ...params,
                    pageIndex: nextPageIndex.value
                } : {
                    pageIndex: nextPageIndex.value
                },
                retry: false,
                ignoreResponseError: true,
                onResponseError: createDefaultErrorHandler(errorCallback),
            })) as PagedData<TQueryItem>
            if (!!!data) {
                return
            }
            isComplete.value = !data.hasNextPage
            const mappedItems = (await Promise.allSettled(data.items.map(asyncQueryMapper))).flatMap(p => p.status === "fulfilled" && !!p.value ? [p.value] : [])
            pushItemsInLocalCollection(mappedItems)
            if (!isComplete.value) {
                nextPageIndex.value++
            }
        } catch (error) {
            console.error("[ItemQueryCollection::FetchMore]", baseUrl, error)
        }
    }

    const fetchOrGetAsync = async (id: string, params?: TSingleParams) => {
        try {
            const existingItem = items.value.get(id)
            if (!!existingItem) {
                return existingItem
            }
            const data = (await $fetch(String(baseUrl) + "/" + id, { query: params })) as TItem
            if (!!data) {
                pushItemsInLocalCollection([data])
            }
            return data ?? undefined
        } catch (error) {
            console.error("[ItemQueryCollection::FetchSingle]", baseUrl, error)
            return undefined
        }
    }

    const pushItemsInLocalCollection = (newItems: TItem[]) => {
        newItems.filter(e => !!e).forEach(e => items.value.set(e.id, e))
        if (!!sorter && items.value.size > 0) {
            items.value = new Map([...items.value]?.sort((a, b) => sorter(a[1], b[1])))
        }
    }

    return {
        items,
        nextPageIndex,
        isComplete,
        fetchOrGetAsync,
        fetchMoreAsync,
        pushItemsInLocalCollection,
    }
}

export const useItemCollection = <TItem extends WithId, TQueryItem = TItem>(
    url: ItemStoreUrl, 
    sorter: (a: TItem, b: TItem) => number,
    asyncQueryMapper: (i: TQueryItem) => Promise<TItem | undefined>,
    errorCallback: (error: ActionError) => any
) => useItemWithQueryCollection<TItem, {}, {}, TQueryItem>(url, sorter, asyncQueryMapper, errorCallback)