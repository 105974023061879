export const useItemDeleter = (baseUrl: ItemStoreUrl, onDeletedCallback: (itemId: string) => any, errorCallback: (error: ActionError) => any) => {
    const deleteItemsAsync = async (...itemIds: string[]) : Promise<Result[]> => {
        return Promise.all(itemIds.map(async itemId => {
            try {
                await $fetch(String(baseUrl).replace(":id", itemId), {
                    method: "DELETE",
                    onResponseError: createDefaultErrorHandler(errorCallback)
                })
                onDeletedCallback(itemId)
                return {
                    success: true
                }
            } catch (error) {
                console.error("Could not delete item " + itemId + ": " + error)
                return {
                    success: false,
                    message: String(error),
                }
            }
        }))
    }

    return {
        deleteItemsAsync
    }
}