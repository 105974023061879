export const useItemUpdater = <TItem extends WithId>(baseUrl: ItemStoreUrl, onUpdatedCallback: (item: TItem) => any, errorCallback: (error: ActionError) => any) => {
    const updateItemAsync = async (item: Partial<TItem> & Required<WithId>) : Promise<Result> => {
        try {
            const updatedItem = (await $fetch(String(baseUrl).replace(":id", item.id), {
                method: "PATCH",
                body: item,
                onResponseError: createDefaultErrorHandler(errorCallback)
            })) as TItem
            if (!!!updatedItem) {
                return {
                    success: false,
                    message: "Item not found"
                }
            }
            onUpdatedCallback(updatedItem)
            return {
                success: true
            }
        } catch (error) {
            console.error("Could not update item " + item.id + ": " + error)
            return {
                success: false,
                message: String(error),
            }
        }
    }

    return {
        updateItemAsync
    }
}